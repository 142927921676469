import { getMetafield } from 'src/functions/util/getMetafield'

import type { Cart, Collection, Product, ProductVariant } from 'src/data/graphql-generated'

const getProductAnalytics = ({ metafields }: Product) => ({
  category: getMetafield(metafields, 'analytics', 'category'),
  subcategory: getMetafield(metafields, 'analytics', 'subcategory'),
})

const mapProductDetail = (product: Product, variant: ProductVariant) => ({
  name: product.title,
  id: variant.sku,
  price: variant.price.amount.toString(),
  brand: product.vendor,
  variant: (product.variantsCount?.count || 1) > 1 ? variant.title : '',
  ...getProductAnalytics(product),
})

const extractCartId = (id?: string) => id?.replace(/gid:\/\/shopify\/Cart\/(\w+)\?/g, '$1')

export const getProductDetail = (product: Product, variant: ProductVariant, isLoggedIn: boolean) => ({
  'ecommerce': {
    detail: {
      products: mapProductDetail(product, variant),
    },
  },
  'event': 'eecomProductDetail',
  'page.type': 'product',
  ...getProductAnalytics(product),
  'isLoggedIn': isLoggedIn,
})

export const getCategoryDetail = (collection: Collection, isLoggedIn: boolean) => {
  const productImpressions = getProductImpressions(collection)

  return {
    'page.type': 'categorypage',
    'category': collection.title,
    'event': 'eecomImpression',
    'ecommerce': {
      category_list: productImpressions.map((p) => p.id),
      impressions: productImpressions,
    },
    'isLoggedIn': isLoggedIn,
  }
}

export const getHomepage = (isLoggedIn: boolean) => ({
  'event': 'eecomImpression',
  'page.type': 'home',
  'isLoggedIn': isLoggedIn,
})

export const getContentPage = (isLoggedIn: boolean) => ({
  'event': 'eecomImpression',
  'page.type': 'content',
  'page.subtype': 'landingpage',
  'isLoggedIn': isLoggedIn,
})

export const getBlogPage = (isLoggedIn: boolean) => ({
  'event': 'eecomImpression',
  'page.type': 'content',
  'page.subtype': 'blog',
  'isLoggedIn': isLoggedIn,
})

export const getAddToCart = (product: Product, variant: ProductVariant, quantity: number, cartId?: string) => ({
  event: 'add_to_cart',
  ecommerce: {
    items: [
      {
        ...mapProductDetail(product, variant),
        quantity: quantity.toString(),
      },
    ],
    cart_id: extractCartId(cartId),
  },
})

export const getCart = (cart: Cart) => ({
  'event': 'eecomCheckout',
  'page.type': 'cart',
  'ecommerce': {
    checkout: {
      actionField: {
        step: '1',
      },
      products:
        cart.lines.nodes.map((item) => ({
          ...mapProductDetail(item.merchandise.product, item.merchandise),
          quantity: item.quantity.toString(),
        })) ?? [],
    },
    cart_id: extractCartId(cart.id),
  },
  'google_tag_params': {
    ecomm_pagetype: 'cart',
    ecomm_pname: cart.lines.nodes.map((item) => item.merchandise.product.title) ?? [],
    ecomm_prodid: cart.lines.nodes.map((item) => item.merchandise.sku) ?? [],
    ecomm_totalvalue: cart.cost.totalAmount.amount,
  },
  'visitor': {
    loggedIn: !!cart.buyerIdentity.customer?.id,
    id: cart.buyerIdentity.customer?.id ?? null,
  },
})

export const getProductImpressions = (collection: Collection) =>
  collection.products.nodes
    .map((product) => {
      const showVariants = getMetafield(collection.metafields, 'layout', 'page_type') === 'gear'
      const [mainVariant] = product.variants.nodes
      const variants = showVariants ? product.variants.nodes : [mainVariant]
      return variants.map((variant) => ({
        name: product.title,
        id: variant.sku,
        price: variant.price.amount,
        brand: product.vendor,
      }))
    })
    .flat()
